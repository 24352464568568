
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















.rgpd-mention {
  display: ruby;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
  font-size: 15px;
}

.rgpd-info {
  font-size: 15x;
}

.checkbox {
  ::v-deep {
    .label {
      font-size: 15px;
      padding-left: 2.9rem;
    }
    .label::before {
      border: 0.1rem solid $c-gray-darkest;
      height: 1.8rem;
      margin-top: -2px;
      width: 1.8rem;
    }
    .label::after {
      left: 0.4rem;
    }
  }
}

.rgpd-plus {
  ::v-deep .action__label {
    text-decoration: underline;
  }
}
